.App {
  height: 100%;
  width: 100%;
  background-color: '#c7c7c7';
}

.AppContent {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}